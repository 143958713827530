import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  TOKENEXPIRED,
  POSTSUCCESS,
  RESETPASSWORDOTPSUCCESS,
  OTPVALIDATESUCESS,
  PASSWORDCHANGEDSUCCESS,
  RETYPEPASSWORDFAILED,
  TERMS,
} from "./types";
import AuthService, { wallet } from "../../_services/auth.service";
import authService from "../../_services/auth.service";
import service from "../../_services/service";
import { printReceipt } from "./printReceipt";
import Cookies from "js-cookie";
import { listServices } from "./services";
export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password, dispatch).then(
    (data) => {
      if (data && (data.err || data.error == true)) handleError(data, dispatch);
      else handleSuccess(data, dispatch);
      return Promise.resolve();
    },
    (error) => {
      handleError(error, dispatch);
      return Promise.reject();
    }
  );
};
let count = 0;
export const retypePassword =
  (password, originalRequest) => async (dispatch) => {
    const mobileNo = JSON.parse(localStorage.getItem("profile")).mobile_no;
    const res = await authService.login(mobileNo, password, dispatch);
    if (!res.isSuccessful && count != 3) {
      count++;
      dispatch({
        type: SET_MESSAGE,
        payload: res.info?.error,
      });
      return;
    } else if (!res.isSuccessful && count == 3) {
      dispatch({ type: RETYPEPASSWORDFAILED });
    }
    dispatch({
      type: SET_MESSAGE,
      payload: null,
    });
    let token = JSON.parse(Cookies.get("user")).token;
    !originalRequest.receipt &&
      service.service(
        originalRequest.url.substring(
          0,
          originalRequest.url.indexOf("authtoken=") + 10
        ) + token,
        originalRequest.data,
        dispatch,
        originalRequest.method,
        originalRequest.callback
      );
    originalRequest.receipt &&
      dispatch(printReceipt(originalRequest.id, originalRequest.lang));
    dispatch({ type: TOKENEXPIRED, payload: { tokenExpired: false } });
    dispatch(listServices())
  };

const handleError = (data, dispatch) => {
  dispatch({
    type: LOGIN_FAIL,
  });
  dispatch({
    type: SET_MESSAGE,
    payload: data.info.additionalInfo
      ? data.info.additionalInfo.info
      : data.info.message,
  });
};

const handleSuccess = (data, dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user: data },
  });
};
export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const changePassword = (data) => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  service.service(
    "/users/changepassword?authtoken=" + token,
    data,
    dispatch,
    "post",
    (data_1) => {
      if (data_1 && data_1.isSuccessful) {
        dispatch({
          type: POSTSUCCESS,
          payload: {
            success: true,
            message: data_1.result.msg,
            passwordChanged: true,
            redirect: "/",
          },
        });
      }
    }
  );
};

export const resetPassWordOtp = (mobileNo, email) => async (dispatch) => {
  return AuthService.resetPassWordOtp(mobileNo, email, dispatch).then(
    (data) => otpHandler(data, dispatch, RESETPASSWORDOTPSUCCESS),
    (error) => otpError(error, dispatch)
  );
};
const otpHandler = (data, dispatch, type) => {
  if (data.err) handleError(data, dispatch);
  else {
    dispatch({ type: type, payload: data.result });
  }
};
const otpError = (error, dispatch) => {
  handleError(error, dispatch);
  return Promise.reject();
};
export const otp = (otp) => async (dispatch) => {
  return otpActionHandler(otp, null, null, dispatch, OTPVALIDATESUCESS);
};
export const getWallet = () => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  wallet(token, dispatch);
};
export const changePassOtp = (password, otp, mobile) => async (dispatch) => {
  return otpActionHandler(
    password,
    otp,
    mobile,
    dispatch,
    PASSWORDCHANGEDSUCCESS
  );
};

const otpActionHandler = async (data1, data2, mobile, dispatch, type) => {
  return (
    data2
      ? AuthService.changePasswordOtp(data1, data2, mobile, dispatch)
      : AuthService.otpVerify(data1, dispatch)
  ).then(
    (data) => otpHandler(data, dispatch, type),
    (error) => otpError(error, dispatch)
  );
};

export const terms = () => async (dispatch) => {
  const token = JSON.parse(Cookies.get("user")).token;
  service.service(
    "/users/terms?authtoken=" + token,
    "",
    dispatch,
    "post",
    (data_1) => {
      if (data_1 && data_1.isSuccessful) {
        dispatch({ type: TERMS, payload: false });
        dispatch({
          type: POSTSUCCESS,
          payload: {
            success: true,
            message: data_1.result.msg,
          },
        });
      }
    }
  );
};
