import { isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
const getDefaultDate = (selected) => {
  if (selected == 5) return { startDate: null, endDate: null };
 else return { startDate: new Date(), endDate: new Date() };
};
const DatePicker = (props) => {
  const [selectionRange, setSelectionRange] = useState({
    ...getDefaultDate(props.currentSelected),
    key: "selection",
  });
  const getSelectedRangeLabel = (selectedRange) => {
    for (const range of defaultStaticRanges) {
      if (
        isSameDay(selectedRange.startDate, range.range().startDate) &&
        isSameDay(selectedRange.endDate, range.range().endDate)
      ) {
        return range.label;
      }
    }
    if (selectedRange.startDate)
      return `${selectionRange.startDate?.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
      })} - ${selectionRange.endDate?.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
      })}`;
    else return "All";
  };
  const [label, setLabel] = useState(getSelectedRangeLabel(selectionRange));
  useEffect(() => {
    setLabel(getSelectedRangeLabel(selectionRange));
  }, [selectionRange]);
  const handleSelect = (ranges) => {
    ranges.selection.endDate?.setHours(23, 59, 59);
    props.changeDateHandler(ranges.selection);
    setSelectionRange(ranges.selection);
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    const index = defaultStaticRanges.findIndex(
      (obj) => obj.label === "This Week"
    );
    if (index != -1) defaultStaticRanges.splice(index, 1);
    defaultInputRanges.splice(1, 1);
    
  }, []);
  return (
    <div className="pull-right">
      <div className="parent" onClick={() => setShow(!show)}>
        <span>{label}</span>
      </div>
      {show && (
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleSelect}
          staticRanges={[
            ...defaultStaticRanges,
            {
              label: "All",
              range: () => ({
                startDate: null,
                endDate: null,
              }),
              isSelected(range) {
                return range;
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default DatePicker;
