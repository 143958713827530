import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
export const Input = (props) => {
  const [isNotValid, setIsNotValid] = useState(false);
  const changeHandler = (e) => {
    props.onChangeHandler(e);
    props.required && validationHandler(e);
  };
  const validationHandler = (e) => {
    if (e.target.value != "") {
      setIsNotValid(false);
    } else setIsNotValid(true);
  };
  return (
    <InputUi
      {...props}
      changeHandler={changeHandler}
      validationHandler={validationHandler}
      isNotValid={props.optional == "yes" ? false : isNotValid}
    />
  );
};

const InputUi = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <label>{props.label || props.name}</label>
      {props.help && <small className="mx-2">( {props.help} )</small>}
      <input
        id={props.id}
        type={props.type ? props.type : "text"}
        autoComplete="off"
        name={props.name}
        defaultValue={props.value}
        disabled={props.disable ? "disabled" : ""}
        onChange={props.onChangeHandler ? props.changeHandler : null}
        onBlur={(e) => {
          props.validationHandler(e);
          props.onBlurHandler &&
            props.onBlurHandler(e, dispatch, props.setError);
        }}
        onFocus={props.onFocusHandler || null}
        placeholder={props.label || props.name}
        min={props.min || null}
        max={props.max || null}
        maxLength={props.mlength || null}
        minLength={props.minL}
        required={props.required || ""}
        className={props.className || ""}
      />
      {!props.validate && props.isNotValid ? (
        <>
          {" "}
          <span className="error">
            {props.name} {t("fields.isrequired")}
          </span>{" "}
          <br></br>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export const DatePiker = (props) => {
  useEffect(() => {
    props.setDisable ? validation(props) : "";
  }, []);
  return (
    <div className="col">
      <label>{props.name}</label>
      <div className="group">
        <Form.Group controlId="dob">
          <Form.Control
            type="date"
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            value={props.getState(props.name, props.defaultValue || "")}
            className="date"
          />
        </Form.Group>
      </div>
    </div>
  );
};

export const Select = (props) => {
  return (
    <div className="col">
      <label>{props.name}</label>
      <Form.Select
        onChange={props.onChangeHandler}
        defaultValue={props.defaultValue || ""}
        name={props.name}
        className="date"
      >
        {!props.noEmptyOption && (
          <option value="">{props.label || props.name}</option>
        )}
        {props.options.map((item, i) => {
          return (
            <option value={item.value || item.name} key={i}>
              {item.name}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
};
export const validation = (props) => {
  if (props.getState(props.name)) props.setDisable(false);
  else props.setDisable(true);
};
