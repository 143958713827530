import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const CustomerPhoneInput = (props) => {
  const { mobile, setMobile, name, disable } = props;
  const [error, setError] = useState();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const validate = (e) => {
    const inputValue = inputRef.current.value;
    if (inputValue.length != 10) {
      inputRef.current.setCustomValidity("Valid mobile number is 10 digits");
    } else {
      inputRef.current.setCustomValidity("");
    }
    e && setMobile(e.target.value.replace(/\D/g, ""));
  };
  const validatePhone = () => {
    const pattern = /^07\d{8}$/;
    if (!pattern.test(mobile)) setError("Invalid mobile No (07xxxxxxxx)");
    else setError("");
  };
  const dispatch = useDispatch();
  return (
    <div>
      <label className={`${error ? "text-danger" : ""}`}>
        {error || props.label || t("fields.CustomerPhone")}
      </label>
      <input
        defaultValue={mobile}
        name={name || "mobile_no"}
        onChange={validate}
        ref={inputRef}
        placeholder={props.label || t("fields.CustomerPhone")}
        minLength={10}
        maxLength={10}
        onBlur={(e) => {
          validatePhone();
          props.onBlurHandler && props.onBlurHandler(e, dispatch);
        }}
        required="required"
        disabled={disable && "disabled"}
      />
    </div>
  );
};

export default CustomerPhoneInput;
