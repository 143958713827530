import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";
export default function Advantages() {
  const { t } = useTranslation();
  return (
    <section className="home-section section">
      <div className="col-12">
        <SectionHeader title={t("advantages-title")}/>
        <div className="row col-advantages services ">
          <LeftCol />
          <RightCol />
        </div>
      </div>
    </section>
  );
}
const LeftCol = () => {
  const { t } = useTranslation();
  const list = [
    t("advantages1"),
    t("advantages2"),
    t("advantages3"),
    t("advantages4"),
    t("advantages5"),
    t("advantages6"),
    t("advantages7"),
  ];
  return (
    <div className="col">
      <ListAdvantage list={list} />
    </div>
  );
};

const RightCol = () => {
  const { t } = useTranslation();
  const list = [
    t("advantages8"),
    t("advantages9"),
    t("advantages10"),
    t("advantages11"),
    t("advantages12"),
    t("advantages13")
  ];
  return (
    <div className="col">
      <ListAdvantage list={list} />
    </div>
  );
};

const ListAdvantage = ({ list }) => {
  return list.map((list, i) => {
    return (
      <div key={i}>
        <FontAwesomeIcon icon={faDotCircle} style={{ paddingRight: 5 }} />{" "}
        {list}
        <p />
      </div>
    );
  });
};
