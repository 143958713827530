import { isMobileAllowed } from "../componets/Admin/user/validate";

export const LegalStatus = [
  { name: "SOLE PROPRIETORSHIP", value: "SOLE PROPRIETORSHIP" },
  { name: "PARTNERSHIP", value: "PARTNERSHIP" },
  { name: "COMPANY", value: "COMPANY" },
];

export const detailsRows = (t, user) => [
  [
    {
      type: "text",
      name: t("otherDetailsFname"),
      default: user && user.incharge_first_name ? user.incharge_first_name : "",
    },
    {
      type: "text",
      name: t("otherDetailsLname"),
      default: user && user.incharge_last_name ? user.incharge_last_name : "",
    },
  ],
  [
    {
      type: "text",
      name: t("otherDetailsMobile"),
      default: user && user.incharge_mobile_no ? user.incharge_mobile_no : "",
    },
    {
      type: "email",
      name: t("otherDetailsEmail"),
      default: user && user.incharge_email ? user.incharge_email : "",
    },
  ],
  [
    {
      type: "number",
      name: t("otherDetailsId"),
      default: user && user.incharge_id_no ? user.incharge_id_no : "",
      mlength: 16,
    },
    {
      type: "date",
      name: t("otherDetailsDob"),
      default:
        user && user.incharge_date_of_birth ? user.incharge_date_of_birth : "",
    },
  ],
];

export const businessDetailsRows = (t, user) => [
  [
    {
      name: t("fields.BusinessName"),
      type: "text",
      default: user ? user.business_name : "",
    },
    {
      name: t("fields.TINNumber"),
      type: "number",
      default: user ? user.tin_no : "",
      mlength: 10,
    },
  ],
  [
    {
      name: t("fields.DateofIncorporation"),
      type: "date",
      default: user ? user.date_of_incorporation : "",
    },
  ],
  [
    {
      name: t("fields.BusinessType"),
      type: "select",
      options: BusinesType,
      default: user ? user.business_type_id : "",
    },
    {
      name: t("fields.LegalStatus"),
      type: "select",
      options: LegalStatus,
      default: user ? user.legal_status : "",
    },
  ],
];

export const BusinesType = [
  { name: "Cyber Cafe", value: "1" },
  { name: "Secretariat Public / Papeterie", value: "2" },
  { name: "Sector Office", value: "3" },
  { name: "Kiosk", value: "4" },
  { name: "Pharmacy", value: "5" },
  { name: "Hair Saloon", value: "6" },
  { name: "Hospitality Business", value: "7" },
  { name: "Petrol station", value: "8" },
  { name: "Other Shop", value: "9" },
];

export const personDetailsRows = (t, user, tsm) => {
  const ar = [];
  tsm &&
    tsm.forEach((element) => {
      ar.push({
        name: `${element.first_name} ${element.last_name} `,
        value: element.id,
      });
    });
  return [
    [
      {
        name: t("fields.FirstName"),
        type: "text",
        default: user ? user.first_name : "",
      },
      {
        name: t("fields.LastName"),
        type: "text",
        default: user ? user.last_name : "",
        validate: true,
      },
    ],
    [
      {
        name: t("fields.MobileNo"),
        type: "text",
        default: user ? user.mobile_no : "",
        onBlurHandler: isMobileAllowed,
      },
      {
        name: t("fields.Email"),
        type: "email",
        default: user ? user.email : "",
      },
    ],
    [
      {
        name: t("fields.IdNumber"),
        type: "number",
        default: user ? user.id_no : "",
        mlength: 16,
      },
      {
        name: t("fields.DateOfBirth"),
        type: "date",
        default: user ? user.date_of_birth : "",
      },
    ],
    [
      {
        name: "TSM",
        type: "select",
        options: ar,
        default: user ? user.tsm : ""
      },
    ],
  ];
};

export const bankDetailsRows = (t, user) => [
  [
    {
      name: t("fields.AccountName"),
      type: "text",
      default: user && user.bankdetail ? user.bankdetail.account_name : "",
    },
    {
      name: t("fields.AccountNumber"),
      type: "text",
      default: user && user.bankdetail ? user.bankdetail.account_no : "",
    },
  ],
  [
    {
      name: t("BankName"),
      type: "text",
      default: user && user.bankdetail ? user.bankdetail.bank_name : "",
    },
    {
      name: t("fields.BankAccountType"),
      type: "select",
      default: user && user.bankdetail ? user.bankdetail.account_type : "",
      options: [
        { name: "Current Account", value: "C" },
        { name: "Saving Account", value: "S" },
      ],
    },
  ],
  [
    {
      name: t("bankbranch"),
      type: "text",
      default: user && user.bankdetail ? user.bankdetail.bank_branch : "",
    },
  ],
];

export const REQUIERD_FIELDS = [
  "mobile_no",
  "first_name",
  "last_name",
  "date_of_birth",
  "email",
  "id_no",
  "province",
  "district",
  "sector",
  "cell",
  "village",
  "venue_description",
  "business_name",
  "date_of_incorporation",
  "legal_status",
  "tin_no",
  "business_type_id",
  "kycdata",
  "account_no",
  "account_name",
  "account_type",
];

export const gpsData = (t) => [
  [
    { name: t("longitude"), type: "text" },
    { name: t("latitude"), type: "text" },
  ],
];
