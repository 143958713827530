import React from "react";
import anastaze from "../../images/anastaze.jpeg";
import female from "../../images/female.png";
import david from "../../images/david.jpeg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import TestimonySlide from "./TestimonySlide";
import SectionHeader from "./SectionHeader";

function Testimony() {
  const { t } = useTranslation();
  const testimonyContent = [
    { testimony: t("testmony1"), name: "MUKAHIRWA Charlotte", image: female },
    { testimony: t("testmony2"), name: "NIYONIRINGIYE David", image: david },
    { testimony: t("testmony3"), name: "Anastase HAGENIMANA", image: anastaze },
  ];
  return (
    <section className=" home-section section">
      <SectionHeader title={t("header.testimonials")} />
      <div className="services">
        <Slider testimonyContent={testimonyContent}/>
      </div>
    </section>
  );
}
const Slider =({testimonyContent})=>{
  return <div id="testimonial-slider">
  <OwlCarousel
    className="slides owl-carousel"
    items={1}
    autoplay={true}
    loop
    autoplayTimeout={20000}
  >
    {testimonyContent.map((testimony, i) => {
      return (
        <TestimonySlide
          key={i}
          image={testimony.image}
          name={testimony.name}
          testimony={testimony.testimony}
        />
      );
    })}
  </OwlCarousel>
</div>
}
export default Testimony;
