import Home from "./componets/home/Home";
import React, { useEffect, useState } from "react";
import FooterHome from "../src/componets/home/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import About from "./componets/home/About";
import { history } from "./_helpers/history";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../src/componets/Spinner";
import Header from "./componets/Agents/Header";
import RetypePassword from "./componets/user/RetypePassword";
import Notification from "./componets/_common/Notification";
import Terms from "./componets/user/Terms";

import { _isAgent } from "./_constant/user_rights";
import RequestPIN from "./componets/Agents/RequestPIN";
import AdminDashboard from "./componets/Admin/Dashboard";
import Dashboard from "./componets/Agents/dashboard/Dashboard";
import Cookies from "js-cookie";
import { ISLOADING, LOGIN_SUCCESS } from "./_redux/actions/types";
function App() {
  const dispatch = useDispatch();
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  useEffect(() => {
    let user = Cookies.get("user") && JSON.parse(Cookies.get("user"));
    if (user && profile)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user },
      });
  }, []);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const isLoading = useSelector(
    ({ loadingReducer }) => loadingReducer.isLoading
  );
  return (
    <div>
      {isLoading && <Spinner />}
      {isLoggedIn ? <Protected /> : <Public />}
    </div>
  );
}

export default App;

const Public = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<About terms={true} />} />
        <Route path="/Privacy" element={<About privacy={true} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Notification />
      <FooterHome />
    </Router>
  );
};

const Protected = () => {
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const dispatch = useDispatch();
  const [body, setBody] = useState([
    <Route
      path="/"
      element={
        _isAgent(profile?.user_rights, "TRANSACT") ? (
          <Dashboard />
        ) : (
          <AdminDashboard />
        )
      }
      key={0}
    />,
  ]);
  useEffect(async () => {
    dispatch({ type: ISLOADING, isLoading: true });
    const obj = await getBody(profile);
    setBody(obj);
    dispatch({ type: ISLOADING, isLoading: false });
  }, []);
  return (
    <Router history={history}>
      <Header profile={profile} />
      <Routes>{body}</Routes>
      <RetypePassword />
      <Notification />
      <RequestPIN />
      <Terms />
      <FooterHome />
    </Router>
  );
};

const getBody = async (profile) => {
  let adminRoutes = [];
  let agentRoutes = [];

  if (
    profile?.admin_rights > 1 ||
    _isAgent(profile.user_rights, "IS_SUPERAGENT")
  ) {
    const moduleAdmin = await import("./routes/protectedAdmin");
    const _protectedAdmin = moduleAdmin.default;
    adminRoutes = _protectedAdmin.map((item, i) => (
      <Route path={item.path} element={item.element} key={i} />
    ));
  }
  if (_isAgent(profile.user_rights, "TRANSACT")) {
    const moduleAgents = await import("./routes/protectedAgents");
    const _protectedAgents = moduleAgents.default;
    agentRoutes = _protectedAgents.map((item, i) => (
      <Route path={item.path} element={item.element} key={i} />
    ));
  }

  return [...agentRoutes, ...adminRoutes];
};
