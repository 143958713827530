import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CLEARPIN, REQUESTPIN, SETPIN } from "../../_redux/actions/types";
import { PasswordInput } from "../user/LoginForm";
import { Button } from "../_common/common";

const RequestPIN = () => {
  const pinReducer = useSelector(({ pinReducer }) => pinReducer);
  const dispatch = useDispatch();
  return (
    <Modal
      show={pinReducer.show}
      backdrop="none"
      centered
      onHide={() => dispatch({ type: CLEARPIN })}
    >
      <Body pinReducer={pinReducer} />
    </Modal>
  );
};
const Body = ({ pinReducer }) => {
  const [PIN, setPIN] = useState("");
  const dispatch = useDispatch();
  let nameInput;
  useEffect(() => {
    nameInput.focus();
  }, [nameInput]);
  const focusHandler = (input) => {
    nameInput = input;
  };
  useEffect(() => {
    if (pinReducer.pin) {
      const data = pinReducer.data;
      data.pin = pinReducer.pin;
      dispatch(pinReducer.postAction(data));
      dispatch({ type: CLEARPIN });
    }
  }, [pinReducer.pin]);
  const click = () => PIN.length == 4 && dispatch({ type: SETPIN, pin: PIN });
  const { t } = useTranslation();
  return (
    <Modal.Body>
      <label className="text-secondary mb-2">Transaction PIN</label>
      <PasswordInput
        password={PIN}
        onChangePass={(e) => setPIN(e.target.value.replace(/\D/g, ""))}
        onkeyPressHandler={click}
        focusHandler={focusHandler}
        className="password"
        max={4}
      />
      <div className="pull-right">
        <Button
          name={t("Send")}
          onClickHandler={click}
          disable={PIN.length == 4 ? false : true}
        />
      </div>
    </Modal.Body>
  );
};

export const requestPin = (data, dispatch, action) => {
  dispatch({
    type: REQUESTPIN,
    postAction: action,
    data: data,
  });
};
export default RequestPIN;
