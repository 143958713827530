import React from "react";
import ShowModal from '../ShowModal'
import Nav from './Nav';



function HomeHeader({ translationHandler }) {

  //show contact us modal
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Nav transilation={(data) => translationHandler(data)} handleShow={() => setModalShow(true)} />
      <ShowModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        iscontact={"true"}
      />
    </>
  );
}

export default HomeHeader;
