import React, { useEffect, useRef, useState } from "react";
import Advantages from "./Advantages";
import ItemeIntro from "./ItemeIntro";
import Services from "./Services";
import Testimony from "./Testmony";
import HomeHeader from "./Header/HomeHeader";
import { useTranslation } from "react-i18next";
import { LinkList } from "./Footer";
import "../../translations/i18n";
import i18n from "../../translations/i18n";
import ShowModal from "./ShowModal";
import { Button, scroll } from "../_common/common";
import { Modal } from "react-bootstrap";
import CustomerPhoneInput from "../_common/CustomerPhoneInput";
import { BusinesType } from "../../_constant/addUser";
import { useDispatch, useSelector } from "react-redux";
import { sendOTP, signup } from "../../_redux/actions/customer";
import { v4 as uuidv4 } from "uuid";
import Location from "../Admin/user/Location";
import { CLEAR } from "../../_redux/actions/types";

const requiredFields = [
  "first_name",
  "last_name",
  "date_of_birth",
  "email",
  "id_no",
  "business_type_id",
  "tin_no",
  "province",
  "district",
  "sector",
  "cell",
  "village",
];
function Home() {
  useEffect(() => {
    scroll();
  }, []);
  const translationHandler = (data) => {
    i18n.changeLanguage(data);
  };
  const [showLoginForm, setShowLoginForm] = useState(false);
  return (
    <div>
      <HomeHeader translationHandler={translationHandler} />
      <HomeSection handleShow={() => setShowLoginForm(true)} />
      <ItemeIntro />
      <Services />
      <Advantages />
      <Testimony />
      <ShowModal
        show={showLoginForm}
        onHide={() => setShowLoginForm(false)}
        islogin={"true"}
      />
    </div>
  );
}

const HomeSection = ({ handleShow }) => {
  const { t } = useTranslation();
  return (
    <section id="home" className="header-image">
      <div className="home-content">
        <div className="container contents">
          <div className="home-content-left">
            <h1>
              {t("welcome")} <br />
              {t("theme")} <br />
            </h1>
            {/* <Register /> */}
       
            <div>
              <button
                href="#home"
                className="login-button"
                onClick={() => handleShow()}
              >
                {t("loginButton")}
              </button>
            </div>
          </div>
        </div>
        <LinkList />
      </div>
    </section>
  );
};

const Register = () => {
  const [show, setShow] = useState(false);
  const [mobile, setMobile] = useState();
  const [uuid, setUuid] = useState();
  const [formData, setFormData] = useState({});
  const [code, setCode] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name.toLowerCase()]: value,
    }));
  };
  const isFormValid = () => {
    return requiredFields.every((field) => Boolean(formData[field]));
  };
  const dispatch = useDispatch();
  const onClickHandler = () => {
    const myUuid = uuidv4();
    setUuid(myUuid);
    dispatch(sendOTP(mobile, myUuid));
  };
  const { t } = useTranslation();
  const uRedecucer = useSelector(({ UserReducer }) => UserReducer);
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
        {/* <WebcamCapture /> */}
          <Modal.Title>Iteme agent self registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!uRedecucer.otpSent && (
            <Form1
              handleInputChange={handleInputChange}
              mobile={mobile}
              setMobile={setMobile}
              uRedecucer={uRedecucer}
              setFormData={setFormData}
            />
          )}
          {uRedecucer.otpSent && (
            <div className="iteme-registration-form">
              <div>
                <label>{t("VerificationCode")}</label>
                <input
                  placeholder="Verification Code"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                  type="password"
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <Button name="Cancel" onClickHandler={() => setShow(false)} />
            {!uRedecucer.otpSent && (
              <Button
                name="Submit"
                disable={isFormValid() && mobile?.length == 10 ? false : true}
                onClickHandler={onClickHandler}
              />
            )}
            {uRedecucer.otpSent && (
              <Button
                name="Submit"
                disable={code ? false : true}
                onClickHandler={() => {
                  dispatch(
                    signup({
                      ...formData,
                      mobile_no: mobile,
                      uuid: uuid,
                      otp: code,
                    })
                  );
                }}
              />
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <div className="register-intro">
        <span>Are you intrested and you want to join ITEME platform? </span>
        <br />
        <span>
          click{" "}
          <b className="register-link" onClick={() => setShow(true)}>
            here
          </b>{" "}
          to register
        </span>
      </div>
    </>
  );
};
const Form1 = ({ handleInputChange, setMobile, mobile, setFormData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: CLEAR });
  }, []);
  return (
    <div className="iteme-registration-form">
      <div className="row">
        <div className="col">
          <label>{t("FirstName")}</label>
          <input
            placeholder={t("FirstName")}
            name="first_name"
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label>{t("LastName")}</label>
          <input
            placeholder={t("LastName")}
            name="last_name"
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>{t("DOB")}</label>
          <input
            type="date"
            className="date-input"
            name="date_of_birth"
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <CustomerPhoneInput
            label={t("MobileNo")}
            setMobile={setMobile}
            mobile={mobile}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>{t("Email")}</label>
          <input
            placeholder={t("Email")}
            name="email"
            onChange={handleInputChange}
          />
        </div>
        <div className="col">
          <label>{t("Idnumber")}</label>
          <input
            placeholder={t("Idnumber")}
            name="id_no"
            onChange={handleInputChange}
            maxLength={16}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label>{t("BusinessType")}</label>
          <select name="business_type_id" onChange={handleInputChange}>
            {BusinesType.map((i) => (
              <option value={i.value} key={i.value}>
                {i.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <label>{t("Tinnumber")}</label>
          <input
            placeholder={t("Tinnumber")}
            name="tin_no"
            onChange={handleInputChange}
            maxLength={10}
          />
        </div>
      </div>
      <Location
        handleChange={(e) => {
          const { name, options} = e.target;
          setFormData((prev) => ({
            ...prev,
            [name.toLowerCase()]: options[e.target.selectedIndex].text,
          }));
        }}
      />
      <div>
        <label>{t("fields.VenueDescription")}</label>
        <input
          placeholder={t("fields.VenueDescription")}
          name="venue_description"
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};
export default Home;
