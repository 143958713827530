import React, { useEffect } from "react";
import iteme from "../../images/iteme_logo_red.png";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageSelector } from "../home/Header/Nav";
import {
  faBarcode,
  faBars,
  faCogs,
  faHome,
  faKey,
  faListAlt,
  faLockOpen,

  faSignOutAlt,
  faTimes,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../_redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18n from "../../translations/i18n";
import { MoneyDisplay, scroll } from "../_common/common";
import { useLocation } from "react-router";
import { rows, subAgentMenu } from "../../_constant/formData";
import { useTranslation } from "react-i18next";
import { _isAgent } from "../../_constant/user_rights";
import { _hasRight } from "../../_constant/admin_rights";
import { CLEAR } from "../../_redux/actions/types";
import Bheader from "./basket/header";
import Help from "./Help";
const Header = (props) => {
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const settings = profile && profile.settings;

  useEffect(() => {
    i18n.changeLanguage(settings.userLang || "kin");
  }, [settings.userLang]);
  const translationHandler = (e, value) => {
    i18n.changeLanguage(value || e.target.value);
  };
  const location = useLocation();
  changeHeaderHeight(location);
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="iteme-header"
      sticky="top"
      id="header"
    >
      <NavBarBody
        translationHandler={translationHandler}
        hideMobileMenu={hideMobileMenu}
        {...props}
      />
    </Navbar>
  );
};
const NavBarBody = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <img
        src={iteme}
        className="logoImg"
        onClick={() => logoNavigateHandler(navigate, location)}
      />
      <HeaderMenu {...props} location={location} />
      <a className="header-div" onClick={() => showMobileMenu()}>
        <FontAwesomeIcon icon={faBars} />
      </a>
    </>
  );
};
const HeaderMenu = ({
  hideMobileMenu,
  translationHandler,
  isFooter,
  profile,
  location,
}) => {
  return (
    <div className="iteme-header-menu" id="headerMenu">
      <Nav className="me-auto me-custom"></Nav>
      <a className="header-button" onClick={() => hideMobileMenu()}>
        <FontAwesomeIcon icon={faTimes} />
      </a>
      {isFooter ? null : (
        <WalletSection
          rights={profile ? profile.admin_rights : 0}
          hideMobileMenu={hideMobileMenu}
        />
      )}
      {profile &&
        _hasRight(profile.admin_rights, "EXPERIMENTAL") &&
        !location.pathname.includes("admin") && <Bheader />}
      {!location.pathname.includes("admin") && (
        <Help
          hideMobileMenu={hideMobileMenu}
          rights={profile ? profile.admin_rights : 0}
        />
      )}
      <LanguageSelector
        languageHandler={translationHandler}
        currentLanguage={i18n.language}
      />
    </div>
  );
};
const WalletSection = ({ rights, hideMobileMenu }) => {
  const walletReducer = useSelector(({ walletReducer }) => walletReducer);
  const wallet = walletReducer.wallet;
  const user = walletReducer.profile;
  return (
    <>
      <RoleBasedHeader
        wallet={wallet}
        rights={rights}
        hideMobileMenu={hideMobileMenu}
        user={user}
      />
      <div className="header-button">
        <FontAwesomeIcon icon={faUser} className="mr-5" />
        {user ? user.first_name + " " + user.last_name : ""}
        <div className="header-submenu">
          <AuthNav rights={rights} hideMobileMenu={hideMobileMenu} />
        </div>
      </div>
    </>
  );
};
const RoleBasedHeader = ({ rights, wallet, hideMobileMenu, user }) => {
  const location = useLocation();
  return (
    <>
      {_isAgent(user.user_rights, "TRANSACT") &&
      user.user_rights >= 1 &&
      !location.pathname.includes("admin") ? (
        <div className="header-button">
          <FontAwesomeIcon icon={faWallet} className="mr-5" />
          <MoneyDisplay
            amount={wallet ? wallet.balance : ""}
            prefix={"RWF "}
            largerFont={true}
          />
          <div className="header-submenu">
            <WalletNav hideMobileMenu={hideMobileMenu} wallet={wallet} />
          </div>
        </div>
      ) : null}
    </>
  );
};
const AuthNav = ({ rights, hideMobileMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const { t } = useTranslation();
  const onClickHandler = (name) => {
    hideMobileMenu();
    scroll();
    navigateHandler({
      name: name,
      navigate: navigate,
      dispatch: dispatch,
    });
  };
  const user = useSelector(({ walletReducer }) => walletReducer).profile;
  let row = [
    { name: t("Logout"), icon: faSignOutAlt, link: "logout" },
    { name: t("ChangePassWord"), icon: faLockOpen, link: "ChangePassWord" },
    { name: t("UserSettings"), icon: faCogs, link: "userSettings" },
  ];

  _hasRight(rights, "EXPERIMENTAL") &&
    row.push({ name: t("branding"), icon: faBarcode, link: "branding" });
  _isAgent(user.user_rights, "TRANSACT") &&
    row.push({ name: t("ChangePIN"), icon: faKey, link: "changepin" });
  if (
    (_isAgent(user.user_rights, "TRANSACT") && user.admin_rights > 1) ||
    _isAgent(user.user_rights, "IS_SUPERAGENT")
  ) {
    row.push({
      name: location.includes("admin") ? "Agent Dashboard" : "Admin Dashboard",
      icon: faHome,
      link: location.includes("admin") ? "" : "admin",
    });
  }
  return <RowUi row={row} onClickHandler={onClickHandler} user={user} />;
};
const navigateHandler = ({ name, navigate, dispatch }) => {
  const url = name.replace(/\s/g, "");
  if (name == "logout") {
    dispatch({ type: CLEAR });
    dispatch(logout());
    navigate("/");
  } else navigate(`/${url}`);
};
const WalletNav = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const onClickHandler = (name) => {
    hideMobileMenu();
    scroll();
    navigateHandler({
      name: name,
      navigate: navigate,
      dispatch: dispatch,
    });
  };
  const { t } = useTranslation();
  const row =
    profile && !profile.superagent_id ? rows(t).report : subAgentMenu(t);
 
  return <RowUi row={row} onClickHandler={onClickHandler} />;
};

export const RowUi = ({ row, onClickHandler }) => {
  return (
    <div>
      {row.map((item, i) => {
        return (
          <span
            onClick={() => {
              if (item.onClickHandler) item.onClickHandler();
              else onClickHandler(item.link);
            }}
            className="header-submenu-item"
            key={i}
          >
            <span className="header-submenu-icon">
              <FontAwesomeIcon
                icon={item.icon}
                size="2x"
                style={{ paddingRight: "5px" }}
              />{" "}
            </span>
            <span className="header-submenu-label">{item.name}</span>
          </span>
        );
      })}
    </div>
  );
};

const showMobileMenu = () =>
  (document.getElementById("headerMenu").style.left = "0%");

const hideMobileMenu = () =>
  (document.getElementById("headerMenu").style.left = "-100%");
const changeHeaderHeight = (location) => {
  useEffect(() => {
    if (location.pathname == "/" || location.pathname == "/admin")
      document.getElementById("header").style.height = "170px";
    else document.getElementById("header").style.height = "70px";
  }, [location.pathname]);
};

const logoNavigateHandler = (navigate, location) => {
  if (location.pathname.includes("admin")) navigate("/admin");
  else navigate("/");
};
export default Header;
