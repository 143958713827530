import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../_common/Input";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../../_redux/actions/location";
import {
  SETCELL,
  SETDISTRICT,
  SETPROVINCE,
  SETSECTOR,
  SETVILLAGE,
} from "../../../_redux/actions/types";
import { Form } from "react-bootstrap";

const handlerLocationState = () => {
  const [districts, setDistricts] = useState();
  const [provinces, setProvinces] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [vilages, setVilages] = useState([]);
  return {
    provinces: provinces,
    districts: districts,
    sectors: sectors,
    cells: cells,
    vilages: vilages,
    setDistricts: setDistricts,
    setSectors: setSectors,
    setVilages: setVilages,
    setCells: setCells,
    setProvinces: setProvinces,
  };
};
const Location = (props) => {
  const stateObj = handlerLocationState();
  const dispatch = useDispatch();
  const location = useSelector(({ locationReducer }) => locationReducer);
  const changeHandler = (e, leveType) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    const id = selectedOption.getAttribute('data-key');
    dispatch(getLocation(Number(id), leveType));
    props.handleChange(e);
  };
  useEffect(() => {
    stateObj.setDistricts(location.districts);
    stateObj.setSectors(location.sectors);
    stateObj.setCells(location.cells);
    stateObj.setVilages(location.villages);
  }, [location]);
  return (
    <LocationUI changeHandler={changeHandler} stateObj={stateObj} {...props} />
  );
};
const rows = (t, changeHandler, stateObj, user) => [
  [
    {
      name: t("fields.Province"),
      options: stateObj.provinces,
      onChange: (e) => changeHandler(e, SETDISTRICT),
      default: user ? user.province : "",
    },
    {
      name: t("fields.District"),
      options: stateObj.districts,
      onChange: (e) => changeHandler(e, SETSECTOR),
      default: user ? user.district : "",
    },
  ],
  [
    {
      name: t("fields.Sector"),
      options: stateObj.sectors,
      onChange: (e) => changeHandler(e, SETCELL),
      default: user ? user.sector : "",
    },
    {
      name: t("fields.Cell"),
      options: stateObj.cells,
      onChange: (e) => changeHandler(e, SETVILLAGE),
      default: user ? user.cell : "",
    },
  ],
];
const LocationUI = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const province = useSelector(
    ({ locationReducer }) => locationReducer
  ).provinces;
  useEffect(() => {
    !province.length && dispatch(getLocation(null, SETPROVINCE));
  }, []);
  useEffect(() => {
    props.stateObj.setProvinces(province);
  }, [province]);
  const ar = rows(t, props.changeHandler, props.stateObj, props.user);
  ar.push([
    {
      name: t("fields.Village"),
      options: props.stateObj.vilages,
      onChange: (e) => props.handleChange(e),
      default: props.user ? props.user.village : "",
    },
  ]);
  return (
    <>
      {ar.map((item, i) => {
        return (
          <div className="row" key={i}>
            <Rows item={item} {...props} />
          </div>
        );
      })}
      {props.getState && (
        <Input
          name={t("fields.VenueDescription")}
          onChangeHandler={props.handleChange}
          value={props.getState(
            t("fields.VenueDescription"),
            props.user && props.user.venue_description
          )}
        />
      )}
    </>
  );
};

const Rows = (props) => {
  return props.item.map((element, key) => {
    return (
      <React.Fragment key={key}>
        {props.getState && (
          <Select
            label={element.name}
            options={element.options || []}
            onChangeHandler={element.onChange}
            name={element.name}
            defaultValue={props.getState(element.name, element.default)}
          />
        )}
        {!props.getState && (
          <Select
            label={element.name}
            options={element.options || []}
            onChangeHandler={element.onChange}
            name={element.name}
          />
        )}
      </React.Fragment>
    );
  });
};
export const Select = (props) => {
  return (
    <div className="col">
      <label>{props.name}</label>
      <Form.Select
        onChange={props.onChangeHandler}
        defaultValue={props.defaultValue || ""}
        name={props.name}
        className="date"
      >
        {!props.noEmptyOption && (
          <option value="">{props.label || props.name}</option>
        )}
        {props.options.map((item, i) => {
          return (
            <option value={item.name} key={i} data-key={item.id}>
              {item.name}
            </option>
          );
        })}
      </Form.Select>
    </div>
  );
};
export default Location;
