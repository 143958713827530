import React, { useEffect, useState } from "react";
import { CLEAR_MESSAGE } from "../../_redux/actions/types";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { faFileDownload, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { getExportData } from "../../_redux/actions/report";
import { passBookList } from "../Agents/Report/Passbook";
export const Validation = (setError, message) => {
  setError(message);
};
export const clearMessage = (dispatch) => {
  useEffect(() => {
    dispatch({ type: CLEAR_MESSAGE });
  }, []);
};
export const Button = (props) => {
  return (
    <button
      title={props.name}
      className="iteme-button"
      onClick={() => (props.onClickHandler ? props.onClickHandler() : null)}
      disabled={props.disable ? true : false}
    >
      {props.name}
    </button>
  );
};

export const MoneyDisplay = ({ amount, prefix, suffix }) => {
  return (
    <NumberFormat
      value={amount}
      displayType={"text"}
      thousandSeparator={" "}
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={prefix ? prefix : ""}
      suffix={suffix ? " " + suffix : ""}
    />
  );
};
export const scroll = () => {
  window.setTimeout(function () {
    $(window).scrollTop(0);
  }, 0);
};
const changeDate = (
  number,
  setStart,
  setEnd,
  setCurrentSelected,
  setPageNo,
  setPageSize,
  startDate,
  endDate
) => {
  // const { endDate, startDate } = switchDate(number);
  // console.log(startDate, endDate);
  setStart(startDate);
  setEnd(endDate);
  // setCurrentSelected(number);
  setPageNo(1);
  setPageSize(10);
};
const switchDate = (number) => {
  switch (number) {
    case 0:
      return {
        startDate: moment(),
        endDate: moment(),
      };
    case 1:
      return {
        startDate: moment().subtract(1, "days"),
        endDate: moment().subtract(1, "days").endOf("day"),
      };
    case 3:
      return {
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
      };
    default:
      return switchDate2(number);
  }
};
const switchDate2 = (number) => {
  switch (number) {
    case 2:
      return {
        startDate: moment().subtract(6, "days"),
        endDate: moment(),
      };

    case 4:
      return {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      };
    case 5:
      return { startDate: undefined, endDate: undefined };
    default:
      return {
        startDate: moment(number),
        endDate: moment(number),
      };
  }
};
export const myHook = ({
  page,
  action,
  url,
  id,
  selected,
  defaultOrderBy,
  direction,
  actionUrl,
  reload,
}) => {
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(page);
  const [start, setStart] = useState(selected == 0 ? moment() : null);
  const [end, setEnd] = useState(selected == 0 ? moment() : null);
  const [currentSelected, setCurrentSelected] = useState(selected == 0 ? 0 : 5);
  const [pageSize, setPageSize] = useState(10);
  const [oldpageSize, setOldPageSize] = useState(pageSize);
  const [sizeChanges, setSizeChanges] = useState(false);
  const [orderBy, setOrderBy] = useState(defaultOrderBy || "");
  const [orderDirection, setOrderDirection] = useState(direction || "DESC");
  const [searchBy, setSearchBy] = useState("");
  const [searchFailed, setSerchFailed] = useState();
  const changeDateHandler = (ranges) =>
    changeDate(
      null,
      setStart,
      setEnd,
      setCurrentSelected,
      setPageNo,
      setPageSize,
      ranges.startDate,
      ranges.endDate
    );
  useEffect(() => {
    let newPage = pageNo;
    if (sizeChanges) {
      newPage = parseInt((page * oldpageSize) / pageSize);
      newPage = newPage < 1 ? 1 : newPage;
      setSizeChanges(false);
    }
    url
      ? dispatch(
          action(
            newPage,
            url,
            start,
            end,
            pageSize,
            orderBy,
            searchBy,
            orderDirection
          )
        )
      : dispatch(
          action({
            pageNo: newPage,
            start: start,
            end: end,
            pageSize: pageSize,
            orderBy: orderBy,
            searchBy: searchBy,
            id: id,
            orderDirection,
            url: actionUrl,
          })
        );
    scroll();
  }, [
    start,
    end,
    pageNo,
    pageSize,
    orderBy,
    searchBy,
    orderDirection,
    searchFailed,
    reload,
  ]);
  const pagingHandler = (page) => setPageNo(page);
  const pageSizeHandler = (size) => {
    setOldPageSize(pageSize);
    setPageSize(size);
    setSizeChanges(true);
  };
  useEffect(() => {
    if (searchBy) setPageNo(1);
  }, [searchBy]);
  return {
    changeDateHandler: changeDateHandler,
    currentSelected: currentSelected,
    pagingHandler: pagingHandler,
    pageSizeHandler: pageSizeHandler,
    pageSize,
    setOrderBy,
    setSearchBy,
    searchBy,
    setOrderDirection,
    orderDirection,
    setSerchFailed,
    searchFailed,
    start,
    end,
  };
};

export const pagingService = (totalRows, pageSize) => {
  let lastPage = 1;
  if (parseInt(totalRows % pageSize) === 0) {
    lastPage = parseInt(totalRows / pageSize);
  } else lastPage = parseInt(totalRows / pageSize) + 1;

  let range = [];
  for (let i = 1; i <= lastPage; i++) {
    range.push(i);
  }
  return range;
};

export const DownloadButton = (props) => {
  const profile = useSelector(({ walletReducer }) => walletReducer).profile;
  const exportD = useSelector(({ reportReducer }) => reportReducer).exportData;
  const dispatch = useDispatch();
  const data = props.isPassBook
    ? downloadList(exportD, profile, props.end, props.start)
    : props.downloadList || props.data;
  const button = document.getElementById("exportbtn");
  useEffect(() => {
    if (data?.length > 2 && props.isPassBook) button?.click();
  }, [data]);
  return (
    <div className={`pull-right ${props.hideOnMobile ? "hide-on-mobile" : ""}`}>
      <p>
        <FontAwesomeIcon
          icon={faFileDownload}
          className="iteme-button"
          title="Download"
          style={{ width: "3.75em" }}
          onClick={() => {
            if (props.isPassBook)
              dispatch(
                getExportData({
                  noPagination: true,
                  start: props.start,
                  end: props.end,
                  orderDirection: props.orderDirection,
                })
              );
            else button?.click();
          }}
        />
        {data?.length > 0 && (
          <CSVLink
            data={data}
            filename={"my-file.csv"}
            id="exportbtn"
          ></CSVLink>
        )}
      </p>
    </div>
  );
};

const downloadList = (data, profile, end, start) => {
  let arr = [["Date", "Status", "Type", "Amount", "Wallet", "TransactionID"]];
  const list = passBookList(data || []);
  if (list[0]) {
    arr.unshift([
      `${list[0].agent?.first_name} ${list[0].agent?.last_name}`,
      profile?.mobile_no,
      `${new Date(start).toLocaleDateString("en-GB")} - ${new Date(
        end
      ).toLocaleDateString("en-GB")}}`,
    ]);
  }
  list?.forEach((item) => {
    arr.push([
      item.date,
      item.status,
      item.type,
      item.amount,
      item.wallet,
      item.transactionID,
    ]);
  });
  return arr;
};
export const Header = (props) => {
  return (
    <div className="form-title">
      {props.logo && (
        <div>
          <img src={props.logo} className="form-logo" />
          <span className="title">{props.title}</span>
        </div>
      )}
      {props.icon && (
        <div>
          <FontAwesomeIcon icon={props.icon} />
          <span className="title-icon">{props.title}</span>
        </div>
      )}
    </div>
  );
};

export const makeid = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getInfo = (item) => {
  const transaction = item.transaction || item;
  const uiRequest =
    transaction &&
    transaction.transaction_request_json &&
    transaction.transaction_request_json.uiRequest;
  const data =
    transaction &&
    transaction.transaction_request_json &&
    transaction.transaction_request_json.formData;
  if (data && data[0] && transaction.type.name != "Donation")
    return data[0].fieldValue + " | " + transaction.customer;
  if (item.agent2agent)
    return item.type == "AGENT TO AGENT SEND"
      ? `Receiver | ${item.agent2agent.receiver.mobile_no}`
      : `Sender | ${item.agent2agent.sender.mobile_no}`;
  if (uiRequest && uiRequest.billerCode == "URUBUTO")
    return uiRequest.payer_code + " | " + uiRequest.phone_number;
  if (uiRequest && uiRequest.billerCode == "CANAL_PLUS_BUY")
    return "Decoder id " + uiRequest.decoder_id;
  if (uiRequest && uiRequest.billerCode == "STARTIMES_BUY")
    return "Subscriber id " + uiRequest.subscriber_id;
  if (item.brandingorder)
    return (
      item.brandingorder.product?.name +
      ` | quantity ${item.brandingorder.quantity}`
    );
  if (transaction.provider?.code === "KTRN_BUY")
    return uiRequest.mobile_no + ` | ${uiRequest.customer_mobile || ""}`;
  else return transaction?.customer;
};

export const getJsonInfo = (item, options) => {
  let transaction = item.trasnaction || item;
  let json = options.request
    ? transaction.transaction_request_json
    : options.response
    ? transaction.transaction_response_json
    : transaction.transaction_status_json;
  if (json && options.sub) json = json[options.sub];
  if (json && !json[options.key] && options.alt) return options.alt;
  return json && json[options.key];
};

export const arraysAreEqual = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;
  if (arr1 && arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (parseInt(arr1[i]) !== parseInt(arr2[i])) {
      return false;
    }
  }

  return true;
};

export const getService = () => {
  const { code } = useParams();
  const item =
    code &&
    JSON.parse(localStorage.getItem("services"))?.find((i) => i.code == code);
  return item;
};
export const fromDetailsToList = (location, navigate) =>
  navigate(location.pathname.split("details")[0]);
export const getFormData = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  return Object.fromEntries(formData.entries());
};
