import { CANAL_FINACIALSTATUS, CLEAR, ECOBANK_FINACIALSTATUS, FDI_FINACIALSTATUS, FLOW_FINACIALSTATUS, ITEME_FINACIALSTATUS, PINDO_FINACIALSTATUS, STARTIMES_FINACIALSTATUS, URUBUTO_FINACIALSTATUS } from "../../actions/types";

const initialState = {
  ecobankFinancialInfo: {},
  urubutoFinancialInfo: {},
  pindoFinancialInfo: {},
  startimesFinancialInfo: {},
  flowFinancialInfo: {},
  itemeFinancialInfo: {},
  canalFinancialInfo: {},
  fdiFinancialInfo:{}
};

export const financialStatus = (state = initialState, action) => {
  switch (action.type) {
    case ECOBANK_FINACIALSTATUS:
      return {
        ...state,
        ecobankFinancialInfo: action.payload.result,
      };
    case URUBUTO_FINACIALSTATUS:
      return {
        ...state,
        urubutoFinancialInfo: action.payload.result,
      };
    case PINDO_FINACIALSTATUS:
      return {
        ...state,
        pindoFinancialInfo: action.payload.result,
      };
    case STARTIMES_FINACIALSTATUS:
      return {
        ...state,
        startimesFinancialInfo: action.payload.result,
      };
    case FLOW_FINACIALSTATUS:
      return {
        ...state,
        flowFinancialInfo: action.payload.result,
      };
    case ITEME_FINACIALSTATUS:
      return {
        ...state,
        itemeFinancialInfo: action.payload.result,
      };
    case CANAL_FINACIALSTATUS:
      return {
        ...state,
        canalFinancialInfo: action.payload.result,
      };
      case FDI_FINACIALSTATUS:
        return {
          ...state,
          fdiFinancialInfo: action.payload.result,
        };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
};
