import Cookies from "js-cookie";
import { duplicateWarning } from "../_redux/actions/biller";
import {
  FAIL,
  ISMOBILE,
  PASSWORDFAIL,
  RECEIPT,
  GETTRANSACTIONENQUIRY,
  AGENTNOTFOUND,
  ISLOADING,
  RRAINFO,
} from "../_redux/actions/types";
import apiHandler from "./apiHandler";
import { logout } from "../_redux/actions/auth";
const service = (
  url,
  data,
  dispatch,
  method,
  callback,
  stayWhenError,
  disableLoading,
  errorMessage
) => {
  apiHandler(data, url, method || "post", dispatch, callback, disableLoading)
    .then((response) => {
      if (response && (response.err || response.error == true)) {
        handleFail(response, dispatch, url, stayWhenError, errorMessage);
      } else {
        handleSuccess(response, dispatch, callback);
      }
    })
    .catch((er) => {
      console.log(er);
    });
};

const handleSuccess = (response, dispatch, callback) => {
  response &&
    response.result &&
    response.result.updateToken &&
    updateToken(response.result);
  if (response && response.result && response.result.duplicate_warning)
    duplicateWarning(dispatch);
  if (response && response.result && response.isPrint)
    return callback(response.res);
  if (response && response.result && response.result.receipt)
    dispatch({
      type: RECEIPT,
      payload:
        response.result.transaction_id ||
        (response.result.header && response.result.header.requestId),
    });
  if (callback) callback(response);
};
const handleFail = (response, dispatch, url, stayWhenError, errorMessage) => {
  let message =
    response &&
    response.info.additionalInfo &&
    response.info.additionalInfo.info;
  if (response.info.statusCode == 601) return dispatch(logout());
  if (url.includes("changepassword")) return handlePassword(response, dispatch);
  // if (typeof message == "string" && message.includes("is already used"))
  //   return handleMobile(message, dispatch);
  let redirect =
    !url.includes("Validate") && !url.includes("check") ? "notValidate" : null;
  if (url.includes("getTransactionEnquiry")) {
    redirect = "admin/transactions";
    return dispatch({ type: GETTRANSACTIONENQUIRY, payload: { close: true } });
  }
  if (url.includes("validateAgentToAgent"))
    return dispatch({
      type: AGENTNOTFOUND,
      payload: message,
    });
  if (errorMessage)
    dispatch({
      type: FAIL,
      payload: {
        error: true,
        message: errorMessage,
      },
    });
  else fail(dispatch, response, redirect, message, stayWhenError);
};
const fail = (dispatch, response, redirect, message, stayWhenError) => {
  dispatch({
    type: FAIL,
    payload: {
      error: true,
      message:
        (typeof message == "string" && response.info.additionalInfo) ||
        response.info.message ||
        response.info.error ||
        "Iteme error",
      redirect: redirect,
      stayWhenError: !response.info.is_pending
        ? stayWhenError && stayWhenError.stayWhenError
        : null,
    },
  });
};
const handlePassword = (response, dispatch) => {
  dispatch({
    type: PASSWORDFAIL,
    payload: {
      message: response.info.additionalInfo.info,
    },
  });
};
const handleMobile = (message, dispatch) => {
  dispatch({
    type: ISMOBILE,
    payload: { isAllowed: false, message: message },
  });
};
const getRRA = async (url, dispatch) => {
  try {
    dispatch({ type: ISLOADING, shortSpinner: true });
    const res = await fetch(process.env.REACT_APP_API_URI + url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({ type: ISLOADING, shortSpinner: false });
    const data = await res.json();
    dispatch({
      type: RRAINFO,
      payload: data.error == 0 ? data.result : data.error,
    });
  } catch (error) {
    console.log(error.message);
  }
};

const updateToken = (res) => {
  const user = JSON.parse(Cookies.get("user"));
  user.token = res.updateToken;
  Cookies.set("user", JSON.stringify(user), {
    sameSite: "None",
    secure: true,
  });
};
export default {
  service,
  getRRA,
};
