import React from "react";
import classNames from "classnames";
const TestimonySlide = (props) => {
  const col8_text = classNames("col-9", "text");
  return (
    <div className="">
      <div className="row">
        <div className={col8_text}>
          <p>{props.testimony}</p>
        </div>

        <div className="col-3 hide-on-mobile">
          <img src={props.image} alt={props.name} className="testimony-image" />
          <h2 className="name-text">{props.name}</h2>
        </div>
      </div>
    </div>
  );
};

export default TestimonySlide;
