import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/iteme.css";
import "./styles/iteme_tablet.css";
import "./styles/iteme_mobile.css";
import App from "./App";
import "./fonts/awesome/fontawesome-webfont.svg";
import "./fonts/awesome/fontawesome-webfont.ttf";
import "./fonts/awesome/fontawesome-webfont.woff";
import "./fonts/awesome/fontawesome-webfont.woff2";
import "./fonts/montserrat/montserrat-black-webfont.woff2";
import "./fonts/montserrat/montserrat-extrabold-webfont.woff2";
import "./fonts/montserrat/montserrat-bold-webfont.woff2";
import "./fonts/montserrat/montserrat-semibold-webfont.woff2";
import "./fonts/montserrat/montserrat-medium-webfont.woff2";
import "./fonts/montserrat/montserrat-regular-webfont.woff2";
import "./fonts/montserrat/montserrat-light-webfont.woff2";
import "./loader.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/text-security/text-security.css"
import "./fonts/fonts.css";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import { Provider } from "react-redux";
import store from "../src/_helpers/store";

setTimeout(
  () =>
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback="...is loading">
          <App />
        </Suspense>
      </Provider>,
      document.getElementById("root")
    ),
  1000
);
