import React from "react";
import { useTranslation } from "react-i18next";
import Categories from "./Categories";
import {
  faChargingStation,
  faFlag,
  faHandHoldingHeart,
  faMoneyCheckAlt,
  faSchool
} from "@fortawesome/free-solid-svg-icons";
export default function Services() {
  const { t } = useTranslation();
  const categories = [
    {
      name: t("services.categories1_title"),
      services: [
        { name: t("services.categories1_services.s1"),icon: faChargingStation },
        { name: t("services.categories1_services.s2"), icon: faFlag },
        { name: t("services.categories1_services.s3"), icon: faSchool },
        { name: t("services.categories1_services.s4"), icon: faMoneyCheckAlt },
        {
          name: t("services.categories1_services.s5"),
          icon: faHandHoldingHeart,
        },
      ],
    }
  ];
  return <Categories categorie={categories} />;
}
