import { useEffect } from "react";
import { mobileNumberAllowed } from "../../../_redux/actions/admin/user";
export const validateMobile = (mobile, props) => {
  if (!mobile || !props.setError) return;
  if (
    (mobile.startsWith("078") ||
      mobile.startsWith("079") ||
      mobile.startsWith("073") ||
      mobile.startsWith("072")) &&
    mobile.length == 10
  ) {
    props.setError("");
    return true;
  } else {
    props.setError("Mobile number is not valid(07XXXXXXXX)");
    return false;
  }
};

const validateEmail = (email, setError) => {
  const validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) return;
  if (email.match(validRegex)) {
    setError("");
    return true;
  } else {
    setError("Email is invalid (exapmle@abc.xx)");
    return false;
  }
};
const validateId = (id, setError) => {
  if (id) {
    setError("");
    return true;
  } else {
    setError("Identification number is required");
    return false;
  }
};
const validateTin = (props, t) => {
  const tin = props.getState(t("fields.TINNumber"));
  if (!tin) return;
  if (/^\d+$/.test(tin) && tin.length == 9) {
    props.setError("");
    return true;
  } else {
    props.setError("TIN number must be 9 digits");
    return false;
  }
};
const validateRequired = (requiredFields) => {
  let isValid = 0;
  requiredFields.forEach((element) => {
    if (element != undefined && element != "") isValid++;
    else return false;
  });
  return isValid == 5 ? true : false;
};
const validateAll = (props, t) => {
  const step1 = [
    props.getState(t("fields.MobileNo")),
    props.getState(t("fields.FirstName")),
    props.getState(t("fields.DateOfBirth")),
    props.getState(t("fields.Email")),
    props.getState(t("fields.IdNumber")),
  ];
  return validateRequired(step1);
};
const validateStep4 = (props, t) => {
  const reqStep4 = [
    props.getState(t("fields.AccountName")),
    props.getState(t("fields.AccountNumber")),
    props.getState(t("BankName")),
    props.getState(t("fields.BankAccountType")),
    props.getState(t("bankbranch")),
  ];
  return validateRequired(reqStep4);
};

export const validateField = (props, setDisable, t) => {
  useEffect(() => {
    const mobile = props.getState(t("fields.MobileNo"));
    const email = props.getState(t("fields.Email"));
    const id = props.getState(t("fields.IdNumber"));
    const value = Object.keys(props.state).length;
    if (props.current == 1) {
      if (
        props.current == 1 &&
        validateAll(props, t) &&
        validateMobile(mobile, props, t) &&
        validateEmail(email, props.setError, t) &&
        validateId(id, props.setError, t)
      )
        setDisable(false);
      else setDisable(true);
    } else if (props.current == 2 && props.size > 3) {
      if (validateTin(props, t) && value >= 17 && props.current == 2)
        setDisable(false);
      else setDisable(true);
    }
  }, [props.state]);
};

export const validateSteps = (setDisable, props, t) => {
  useEffect(() => {
    if (props.isUpdate) {
      setDisable(false);
      return;
    }
  }, []);
  useEffect(() => {
    if (props.current == 3) {
      setDisable(false);
      return;
    }
    if (props.current == 4 && validateStep4(props, t)) {
      setDisable(false);
      return;
    } else setDisable(true);
    if (
      ((props.current == 5 && props.getState("RegistrationCertificate")) ||
        props.getState("TradingLicence")) &&
      props.getState("OwnerId") &&
      props.getState("PhotoOwner")
    )
      setDisable(false);
  }, [props.state]);
  validateField(props, setDisable, t);
};

export const isMobileAllowed = (e, dispatch) => {
  dispatch(mobileNumberAllowed(e.target.value));
};
