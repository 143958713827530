import React from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";
function ItemeIntro() {
    const{ t }= useTranslation();
        return (

            <section  className="home-section section">
                <div className="services">
                   <SectionHeader title={t("itemeIntro.title")} />
                    <div className="col-12">
                        <p className="lead">
                        {t("itemeIntro.body")}
                        </p>
                    </div>
                </div>
            </section>

        )
}

export default ItemeIntro;